/* eslint-disable no-shadow */
import React, { useCallback, useState } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Icon from '../components/Icon';
import OtherProducts from '../components/OtherProducts';
import GetStarted from '../components/GetStarted';
import FAQ from '../components/FAQ';
import Scrollspy from '../components/Scrollspy';
import SliderUseCase from '../components/SliderUseCase';
import ModalVideo from '../components/ModalVideo';
import Code from '../components/Code';

import '../styles/components/left-text-hero.scss';
import '../styles/components/section-features.scss';
import '../styles/components/img-text-block.scss';
import '../styles/components/terminal-asset.scss';
import '../styles/components/feature-heading.scss';
import '../styles/components/section-developers.scss';
import '../styles/components/other-products.scss';
import '../styles/cashgram.scss';
import '../styles/theme.scss';

const terminalLine = '!text-tiny md:!text-2sm';

const useCases = [
  {
    id: 0,
    logoBg: '#336CA2',
    usecaseFeature: 'Instant security deposit refund',
    usecaseDescription: (
      <>
        <p className="text-2sm md:text-md mb-[15px] min-h-[120px]">
          Furlenco the furniture e-renting platform is delighting customers with{' '}
          <span className="font-semibold">instant refund</span> of{' '}
          <span className="font-semibold">security deposits</span> at the end of
          every subscription with Cashgram.
        </p>
      </>
    ),
  },
  {
    id: 1,
    logoBg: '#FC2779',
    usecaseFeature: 'Instant refund for COD orders',
    usecaseDescription: (
      <>
        <p className="text-2sm md:text-md mb-[15px] md:min-h-[120px] xl:min-h-none">
          Nykaa is winning customer trust and loyalty more than ever before by
          doing{' '}
          <span className="font-semibold">instant refunds for COD orders</span>{' '}
          using Cashgram.
        </p>
      </>
    ),
  },
  {
    id: 2,
    logoBg: '#603084',
    usecaseFeature: 'Instant disbursal of incentives',
    usecaseDescription: (
      <>
        <p className="text-2sm md:text-md mb-[15px] min-h-[120px]">
          Apollo tyres automated the entire process of sending incentive
          payments to agents and influencers with Cashgram, reducing the
          company&apos;s effort to minimal.
        </p>
      </>
    ),
  },
];

const Cashgram = (props) => {
  const [accordionAnimImage, setAccordionAnimImage] = useState(0);

  const getAccordionAnimImage = useCallback((id) => {
    setAccordionAnimImage(id);
  }, []);

  const [OpenVideoModal, setOpenVideoModal] = useState(false);

  const OpenVideoModalToggle = () => {
    setOpenVideoModal((state) => !state);
    document.body.classList.toggle('overflow');
  };

  return (
    <Layout
      signInLink="https://merchant.cashfree.com/merchant/login?source-action=Cashgram%20Page&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=Cashgram%20Page&action=Sign%20Up&button-id=StartNow_Navbar"
      {...props}>
      <SEO
        title="Payout Links - Send money instantly using Cashgram"
        description="Payout links to send money without collecting bank account details. Use Cashgram to pay vendors, partners, customers with only their email, mobile no."
        url="https://www.cashfree.com/cashgram/"
        keywords={[
          'Payout links',
          'instant payout links',
          'cashgram payout link',
        ]}
      />

      <section className="overflow-hidden relative pt-[111px] pb-[38px] md:pt-[207px] md:pb-[105px]">
        <div className="container relative">
          <div className="relative z-[10] max-w-[600px]">
            <span className="block font-heading mb-3 md:mb-5 text-xl md:text-2xl font-semibold max-w-[500px]">
              <h1 className="inline">Cashgram</h1> for growing businesses
            </span>
            <span
              className="block md:text-2.5md text-opacity-80 mb-[30px]"
              style={{ maxWidth: 475 }}>
              <h2 className="inline font-body font-normal">Payout links</h2> to
              send money without collecting recipient&apos;s bank account
              details. Delight your users by paying them faster.
            </span>

            <ul className="p-0 mt-8 mb-9 list-none max-w-full flex flex-wrap md:flex-nowrap items-center justify-start">
              {[
                {
                  id: 0,
                  listItem: (
                    <h2 className="inline font-body font-normal">
                      Instant Payouts
                    </h2>
                  ),
                },
                {
                  id: 1,
                  listItem: 'Multiple payout modes supported',
                },
                {
                  id: 2,
                  listItem: 'Highly secure',
                },
              ].map(({ id, listItem }) => (
                <li className=" mb-[15px] mr-[15px] flex items-center" key={id}>
                  <span className="w-5 h-5 p-1.5 flex justify-center items-center rounded-full mr-2 bg-cf-primary">
                    <Icon
                      name="tick"
                      width="9.21px"
                      height="6.29px"
                      fill="#fff"
                    />
                  </span>
                  <span className="block font-body !m-0 text-[15px]">
                    {listItem}
                  </span>
                </li>
              ))}
            </ul>
            <div className="flex flex-wrap md:flex-nowrap justify-start mb-12 lg:mb-0">
              <a
                className="button button-green w-full md:w-auto"
                href="https://merchant.cashfree.com/merchants/signup?source-action=Cashgram%20Page&action=Sign%20Up&button-id=StartNow_Hero">
                Create Account <span className="chevron" />
              </a>
            </div>
          </div>
          <div className="lg:absolute lg:top-[-50px] xl:top-[-150px] right-0 xl:right-[-30px] w-full lg:w-[400px] xl:w-[670px] z-[11] scale-110 md:scale-100">
            <video autoPlay muted playsInline loop className="chrome-video">
              <source src="/img/cashgram/nykaa.webm" type="video/webm" />
              <source src="/img/cashgram/nykaa_H264.mp4" type="video/mp4" />
            </video>
            <video
              autoPlay
              muted
              playsInline
              loop
              className="hidden safari-video">
              <source src="/img/cashgram/nykaa_H264.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </section>

      <section className="mt-9 md:mt-0 xl:mt-16 mb-6 md:mb-16">
        <div className="small-container">
          <div className="relative overflow-hidden">
            <div className="row justify-start md:justify-center max-w-[1036px] mx-auto marquee marquee-2 marqee-mob">
              <span className="mr-10">
                <img
                  className="mx-auto"
                  src="/img/wakefit.png"
                  alt="wakefit"
                  style={{ width: 96, maxWidth: 96 }}
                />
              </span>
              <span className="mr-10">
                <img
                  className="mx-auto"
                  src="/img/dunzo-black.svg"
                  alt="dunzo"
                  style={{ width: 73, maxWidth: 73 }}
                />
              </span>
              <span className="mr-10">
                <img
                  className="mx-auto"
                  src="/img/lenskart.svg"
                  alt="lenskart"
                  style={{ width: 128, maxWidth: 128 }}
                />
              </span>
              <span className="mr-10">
                <img
                  className="mx-auto"
                  src="/img/noise.svg"
                  alt="noise"
                  style={{ width: 90, maxWidth: 90 }}
                />
              </span>
              <span className="mr-10">
                <img
                  className="mx-auto"
                  src="/img/zoomcar.svg"
                  alt="zoomcar"
                  style={{ width: 90, maxWidth: 90 }}
                />
              </span>
              <span className="mr-10">
                <img
                  className="mx-auto"
                  src="/img/mi.svg"
                  alt="mi"
                  style={{ width: 48 }}
                />
              </span>
            </div>
          </div>
        </div>
      </section>

      <Scrollspy
        leadContent={
          <div
            className="payout-nav-logo row row-no-margin justify-flex-start"
            style={{ minWidth: '190px' }}>
            <img
              src="/img/icons/cashgram.svg"
              alt="cashgram-icon"
              width="32px"
            />
            <span>Cashgram</span>
          </div>
        }>
        <section
          data-spy-title="Features"
          data-spy-id="features"
          className="py-12 md:py-[128px]">
          <div className="container">
            <div className="flex flex-col">
              <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                GET STARTED RIGHT
              </p>
              <span className="block font-heading text-lg md:text-xl font-semibold text-cf-hero max-w-[600px] mb-3 md:mb-0">
                Payouts without the hassle of collecting bank accounts details
              </span>
              <div className="flex flex-wrap md:flex-nowrap justify-start order-1 md:order-none mt-8 md:mt-10 md:mb-16">
                <div className="w-1/2 md:w-auto pr-2">
                  <a
                    className="button button-green w-full md:w-auto"
                    href="https://merchant.cashfree.com/merchants/signup?source-action=Cashgram%20Page&action=Sign%20Up&button-id=StartNow_InstantPayout24x7">
                    Create Account <span className="chevron" />
                  </a>
                </div>
                <div className="w-1/2 md:w-auto pl-2">
                  <a
                    className="button button-light no-shadow border-cf-stroke w-full md:w-auto"
                    href="https://www.cashfree.com/contact-sales?source-action=Cashgram%20Page&action=Contact%20Sales&button-id=ContactSales_InstantPayout24x7">
                    Contact Sales <span className="chevron" />
                  </a>
                </div>
              </div>
              <div className="flex flex-nowrap md:flex-wrap justify-start mx-[-20px]">
                <div className="flex-auto px-[20px] w-full md:w-6/12 hidden md:block">
                  <div className="relative ">
                    <div
                      className={`hidden static transform-none mx-auto transition-all duration-300 ease${
                        accordionAnimImage === 0 ? ' !block' : ''
                      }`}>
                      <img
                        src="/img/cashgram/cashgram-instant-payouts.jpg"
                        alt=""
                      />
                    </div>
                    <div
                      className={`hidden static transform-none mx-auto transition-all duration-300 ease${
                        accordionAnimImage === 1 ? ' !block' : ''
                      }`}>
                      <img
                        src="/img/cashgram/cashgram-built-scale.jpg"
                        alt=""
                      />
                    </div>
                    <div
                      className={`hidden static transform-none mx-auto transition-all duration-300 ease${
                        accordionAnimImage === 2 ? ' !block' : ''
                      }`}>
                      <img
                        src="/img/cashgram/cashgram-secure-reliable.jpg"
                        alt=""
                      />
                    </div>
                    <div
                      className={`hidden static transform-none mx-auto transition-all duration-300 ease${
                        accordionAnimImage === 3 ? ' !block' : ''
                      }`}>
                      <img
                        src="/img/cashgram/cashgram-customizable.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-auto px-[20px] w-full md:w-6/12">
                  <div>
                    <div className="md:pt-10">
                      <FAQ
                        supportLink={false}
                        arrow="arrow"
                        getActive={getAccordionAnimImage}
                        isPayouts
                        alwaysOpen
                        borderLast
                        data={[
                          {
                            id: 0,
                            q: (
                              <>
                                <span className="inline-block mr-3 md:my-2">
                                  <img
                                    className="inline w-[32px]"
                                    src="/img/icons/instant-payouts.svg"
                                    alt=""
                                  />
                                </span>
                                Instant payouts- 24x7
                              </>
                            ),
                            a: (
                              <>
                                <div className="md:pl-[44px]">
                                  Do instant payouts to any bank account/UPI
                                  ID/card/Paytm or AmazonPay wallet instantly
                                  even on a bank holiday.
                                  <img
                                    className="md:hidden mt-6"
                                    src="/img/cashgram/cashgram-instant-payouts.jpg"
                                    alt=""
                                  />
                                </div>
                              </>
                            ),
                          },
                          {
                            id: 1,
                            q: (
                              <>
                                <span className="inline-block mr-3 md:my-2">
                                  <img
                                    className="inline w-[32px]"
                                    src="/img/icons/built-scale.svg"
                                    alt=""
                                  />
                                </span>
                                Built for scale
                              </>
                            ),
                            a: (
                              <>
                                <div className="md:pl-[44px]">
                                  Make upto 10,000 transfers in a single API
                                  call or via simple excel file upload.
                                  <img
                                    className="md:hidden mt-6"
                                    src="/img/cashgram/cashgram-built-scale.jpg"
                                    alt=""
                                  />
                                </div>
                              </>
                            ),
                          },
                          {
                            id: 2,
                            q: (
                              <>
                                <span className="inline-block mr-3 md:my-2">
                                  <img
                                    className="inline w-[32px]"
                                    src="/img/icons/secure-reliable.svg"
                                    alt=""
                                  />
                                </span>
                                Secure and reliable
                              </>
                            ),
                            a: (
                              <>
                                <div className="md:pl-[44px]">
                                  Ensure secure and reliable payouts every time
                                  with in-built features:
                                  <ul className="mt-2.5 !p-0 list-none max-w-full">
                                    {[
                                      {
                                        id: 0,
                                        listItem:
                                          'Auto-retry of transfers to ensure higher success rate',
                                      },
                                      {
                                        id: 1,
                                        listItem:
                                          'Receiver name verification prior to transfer',
                                      },
                                      {
                                        id: 2,
                                        listItem:
                                          'Maker - checker flow for prior to transfer approvals',
                                      },
                                    ].map(({ id, listItem }) => (
                                      <li
                                        className=" mb-[15px] mr-[30px] flex md:items-center"
                                        key={id}>
                                        <span className="w-5 h-5 p-1.5 flex justify-center items-center rounded-full mr-2.5 bg-cf-primary translate-y-1 md:translate-y-0">
                                          <Icon
                                            name="tick"
                                            width="9.21px"
                                            height="6.29px"
                                            fill="#fff"
                                          />
                                        </span>
                                        <h3 className=" font-body text-opacity-80 !m-0 text-md">
                                          {listItem}
                                        </h3>
                                      </li>
                                    ))}
                                  </ul>
                                  <img
                                    className="md:hidden mt-6"
                                    src="/img/cashgram/cashgram-secure-reliable.jpg"
                                    alt=""
                                  />
                                </div>
                              </>
                            ),
                          },
                          {
                            id: 3,
                            q: (
                              <>
                                <span className="inline-block mr-3 md:my-2">
                                  <img
                                    className="inline w-[32px]"
                                    src="/img/icons/customizable.svg"
                                    alt=""
                                  />
                                </span>
                                Customizable
                              </>
                            ),
                            a: (
                              <>
                                <div className="md:pl-[44px]">
                                  Customize your Cashgram links with your
                                  company logo and colour to reflect your brand.
                                  <img
                                    className="md:hidden mt-6"
                                    src="/img/cashgram/cashgram-customizable.jpg"
                                    alt=""
                                  />
                                </div>
                              </>
                            ),
                          },
                        ]}
                        style={{
                          border: 'none',
                          margin: '0',
                          padding: '0',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section-developers cashgram-bg py-12 md:py-24"
          data-spy-title="Integrations"
          data-spy-id="integrations">
          <img
            src="/img/cashgram-bg-2.svg"
            className="parallax-img parallax-img-2"
            alt=""
          />
          <div className="container position-relative">
            <div className="single-dev-block !pb-2 md:!pb-[116px]">
              <span className="block text-sm font-semibold text-cf-white pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                ENGINEERED FOR YOU
              </span>
              <span className="block font-heading text-lg md:text-xl font-semibold text-cf-white mb-7.5x">
                Quick and easy integrations
              </span>
              <ul className="check-list-round max-w-[600px]">
                {[
                  {
                    id: 0,
                    item: 'Go live in minutes with easy to integrate REST APIs',
                  },
                  {
                    id: 1,
                    item: (
                      <>
                        Use{' '}
                        <span className="text-cf-green">
                          sample integration
                        </span>{' '}
                        kits to try out integrations using Test{' '}
                        <span className="text-cf-green">API credentials</span>{' '}
                        before going live. API libraries available in multiple
                        languages
                      </>
                    ),
                  },
                  {
                    id: 2,
                    item: (
                      <>
                        With webhooks get notified on transfer status - success,
                        pending etc in{' '}
                        <span className="inline-block">real-time</span>
                      </>
                    ),
                  },
                ].map(({ id, item }) => (
                  <li key={id}>
                    <span className="mt-0.5">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    <p>{item}</p>
                  </li>
                ))}
              </ul>

              <a
                href="https://docs.cashfree.com/reference/pg-new-apis-endpoint"
                className="button button-green md:min-w-[278px]">
                View API documentation <span className="chevron" />
              </a>

              <div className="terminal-asset mt-[60px]">
                <div className="terminal-tabs before:!w-[calc(100%-68px)]">
                  <span className="!min-w-[68px] md:!min-w-[150px] active">
                    cURL
                  </span>
                  <span className="!min-w-[68px] md:!min-w-[150px] ">Java</span>
                  <span className="!min-w-[68px] md:!min-w-[150px] ">
                    Python
                  </span>
                  <span className="!min-w-[68px] md:!min-w-[150px] ">PHP</span>
                  <span className="!min-w-[68px] md:!min-w-[150px] ">
                    Node JS
                  </span>
                </div>

                <div className="terminal-body !py-4 !px-6 md:!py-6">
                  <div className="terminal-content">
                    <p className={terminalLine} data-line-number="1">
                      <Code
                        language="language-javascript"
                        code={`"curl -X POST / 
    'https://{{Host%20Url}}/payout/v1/payout/v1/createCashgram' 
    -H 'Accept: */*' 
    -H 'Authorization: Bearer {{token}}' 
    -H 'Content-Type: application/json' 
    -d '{
      "cashgramId": "cf6",
      "amount": "1.00",
      "name": "john",
      "email": "john@cashfree.com",
      "phone": "9000000001",
      "linkExpiry": "2019/11/9",
      "remarks": "sample cashgram",
      "notifyCustomer": 1
    }'
                        `}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-dev-block">
              <div className="row  row-no-margin justify-flex-start mobile-wrap">
                <img
                  className="order-1 md:order-none w-full"
                  src="/img/cashgram/cashgram-link.svg"
                  alt=""
                  style={{ maxWidth: 357 }}
                />

                <div className="no-code-solution">
                  <h4 className="body-font white">No code solutions</h4>
                  <ul className="check-list-round my-0 md:mt-5 md:mt-8">
                    {[
                      {
                        id: 0,
                        item:
                          'Generate a single Cashgram payout link or do bulk payouts easily from the dashboard.',
                      },
                      {
                        id: 1,
                        item: `Just enter the receiver's mobile number/email address to send Cashgram links, notify receiver over SMS, email or WhatsApp and complete payouts within minutes.`,
                      },
                    ].map(({ id, item }) => (
                      <li key={id}>
                        <span className="mt-0.5">
                          <Icon
                            name="tick"
                            width="9.21px"
                            height="6.29px"
                            fill="#fff"
                          />
                        </span>
                        <p>{item}</p>
                      </li>
                    ))}
                  </ul>

                  <div className="row relative py-4 px-5 border border-white border-opacity-10 rounded bg-white bg-opacity-5 md:max-w-[320px] mt-6 mb-[40px] mx-0 md:mb-0">
                    <div className="ribbon">
                      <img
                        className="max-w-[46px]"
                        src="/img/ribbon-purple.svg"
                        alt="ribbion"
                      />
                      <span
                        style={{
                          top: '8px',
                          left: '3px',
                          fontSize: '12px',
                        }}>
                        NEW
                      </span>
                    </div>
                    <img
                      className="mr-3"
                      src="/img/whatsapp-icon.svg"
                      alt="whatsapp"
                    />
                    <p
                      className="white font-semibold text-2sm md:text-tiny"
                      style={{
                        margin: '0',
                        flex: '1',
                      }}>
                      Integration with WhatsApp for best customer experience
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="content-image-block">
          <section className="for-enterprises py-12 md:pt-[128px] md:pb-[100px]">
            <div className="container">
              <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                For enterprises
              </p>
              <span className="block font-heading text-lg md:text-xl font-semibold text-cf-default mb-8 md:mb-[60px]">
                Secure and highly reliable at scale
              </span>

              <div className="row mobile-wrap items-center">
                <div className="column order-1 md:order-none">
                  <div className="">
                    <img
                      className="hidden md:block"
                      src="/img/cashgram/beneficiary-name.svg"
                      alt=""
                    />
                    <img
                      className="md:hidden"
                      src="/img/cashgram/beneficiary-name-mob.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="max-w-[500px]">
                    <h2 className="text-3md md:text-lg font-semibold tracking-wide font-body mb-2 md:mb-5">
                      Beneficiary name verification
                    </h2>
                    <p className="font-body text-2sm md:text-md">
                      Cashfree Payments does a penny transfer to the bank
                      account and verifies that the bank account is valid and
                      matches the receiver&apos;s name with the account holder’s
                      name in bank records - ensuring the amount goes only to
                      the intended beneficiary.
                    </p>
                    <div className="mt-6 md:mt-10 mb-7 md:mb-0">
                      <a
                        href="https://merchant.cashfree.com/merchants/signup?source-action=Cashgram%20Page&action=Sign%20Up&button-id=StartNow_BeneficiaryNameVerification"
                        className="button button-green">
                        Create Account <span className="chevron" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="pb-[52px] md:pb-[105px]">
            <div className="container">
              <div className="row mobile-wrap items-center">
                <div className="column">
                  <div className="max-w-[500px]">
                    <h2 className="text-3md md:text-lg font-semibold tracking-wide font-body mb-2 md:mb-5">
                      Maker - Checker flow
                    </h2>
                    <p className="font-body text-2sm md:text-md">
                      Assign different roles to team members. Define Maker and
                      Checker flows and minimize the chances of mistakes due to
                      human errors, malpractices, etc.
                    </p>
                    <div className="mt-6 md:mt-10 mb-7 md:mb-0">
                      <a
                        href="https://merchant.cashfree.com/merchants/signup?source-action=Cashgram%20Page&action=Sign%20Up&button-id=StartNow_MakerCheckerFlow"
                        className="button button-green">
                        Create Account <span className="chevron" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div data-watch-visibility className="position-relative ">
                    <img
                      className="hidden md:block"
                      src="/img/cashgram/maker-checker.svg"
                      alt=""
                    />
                    <img
                      className="md:hidden"
                      src="/img/cashgram/maker-checker-mob.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="bg-cf-light-grey pt-12 md:pt-24 pb-10 md:pb-14 overflow-hidden">
          <div className="container">
            <div className="flex flex-col">
              <span className="block font-heading text-lg md:text-xl font-semibold mb-3 md:mb-5 max-w-[778px]">
                One solution, fits many business use cases
              </span>

              <span className="block text-2sm md:text-md mb-[30px] max-w-[630px]">
                An easy-to-share{' '}
                <h2 className="inline font-body font-normal">payout link</h2>{' '}
                used by businesses to send money to their customers, vendors, or
                partners when only their email address/phone number
                is&nbsp;known.
              </span>
              <div className="mt-4 md:mt-0 md:mb-[64px] order-1 md:order-none">
                <a
                  className="button button-green w-full md:w-auto"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=Cashgram%20Page&action=Sign%20Up&button-id=StartNow_OneSolutionFitsBusiness">
                  Create Account <span className="chevron" />
                </a>
              </div>
              <div className="flex flex-wrap items-stretch mx-[-7px] md:mx-[-20px]">
                {[
                  {
                    id: 0,
                    icon: '/img/icons/retail.svg',
                    heading: 'Retail and E-commerce',
                    description:
                      'Refund cash-on-delivery orders instantly, process cashbacks and\xa0more',
                  },
                  {
                    id: 1,
                    icon: '/img/icons/online-gaming.svg',
                    heading: 'Online Gaming',
                    description: 'Distribute prize money to\xa0players',
                  },
                  {
                    id: 2,
                    icon: '/img/icons/rental-business.svg',
                    heading: 'Rental Business',
                    description:
                      'Refund security deposit for any kind of rental business - car, furniture, or\xa0appliances',
                  },

                  {
                    id: 3,
                    icon: '/img/icons/ngos.svg',
                    heading: 'NGOs & Political Organisations ',
                    description: 'Reimburse field agents and\xa0volunteers ',
                  },
                  {
                    id: 4,
                    icon: '/img/icons/corporates.svg',
                    heading: 'Corporates',
                    description:
                      'Pay marketing affiliates and disburse reimbursements to\xa0employees',
                  },
                  {
                    id: 5,
                    icon: '/img/icons/bfsi.svg',
                    heading: 'BFSI',
                    description:
                      'Settle insurance claims and pay DSA\xa0incentives',
                  },
                  {
                    id: 6,
                    icon: '/img/icons/healthcare.svg',
                    heading: 'Healthcare',
                    description:
                      'Pay field agents, handle reimbursements, send\xa0refunds ',
                  },
                  {
                    id: 7,
                    icon: '/img/icons/travel.svg',
                    heading: 'Travel and Hospitality',
                    description:
                      'Pay commissions to vendors, handle reimbursements and do\xa0refunds',
                  },
                ].map(({ id, description, heading, icon }) => (
                  <div
                    key={id}
                    className="w-1/2 md:w-3/12 px-[7px] md:px-[20px] mb-4 md:mb-10 ">
                    <div className="bg-cf-white px-2 md:px-4 py-7 md:py-8 h-full rounded transition-all duration-300 hover:shadow-card">
                      <div className="w-[30px] md:w-[40px] h-[30px] md:h-[40px] mb-5 md:mb-6">
                        <img
                          className="max-w-[30px] md:max-w-[40px] max-h-[30px] md:max-h-[40px] "
                          src={icon}
                          alt=""
                        />
                      </div>
                      <div>
                        <h3 className="font-body font-bold md:font-semibold mb-2">
                          {heading}
                        </h3>
                        <p className="text-2sm">{description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <div className="py-12 md:py-[128px] overflow-hidden">
          <div className="container">
            <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
              Use cases for various industries
            </p>
            <span className="block font-heading text-lg md:text-xl font-semibold text-cf-hero mb-3 md:mb-5">
              Cashgram for <h2 className="inline">instant payouts</h2>
            </span>
            <p className="text-2sm md:text-md max-w-[650px] mb-[32px] md:mb-[60px]">
              Make sending out money to beneficiaries, simple and easy. Improve
              customer/vendor/ partner experience by making instant payouts in
              their preferred mode of transaction.
            </p>

            <div>
              <SliderUseCase>
                {useCases.map(
                  ({ id, usecaseDescription, logoBg, usecaseFeature }) => (
                    <div
                      key={id}
                      className="w-full md:w-4/12 px-[20px] pb-10 h-full">
                      <div className="bg-cf-white shadow-box rounded block h-full pb-20 md:pb-[90px] relative transition-all min-h-[425px] md:min-h-[451px]  duration-300 hover:shadow-card">
                        <div
                          className="relative flex items-center justify-center min-h-[200px] w-full"
                          style={{ backgroundColor: logoBg }}>
                          {id === 0 && (
                            <>
                              <img
                                src="/img/furlenco-white.png"
                                width={175}
                                alt="furlenco"
                              />
                              <button
                                type="button"
                                onClick={OpenVideoModalToggle}
                                className="bg-black bg-opacity-25 absolute bottom-0 left-0 right-0 py-3 md:py-4 text-center group">
                                <span className="text-cf-white font-medium text-2sm flex items-center justify-center mx-auto transition-all duration-300 group-hover:text-cf-green">
                                  Watch Video
                                  <svg
                                    className="inline-block ml-2"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect
                                      className="transition-all duration-300 group-hover:fill-cf-green"
                                      width="20"
                                      height="20"
                                      rx="10"
                                      fill="white"
                                    />
                                    <path
                                      className="transition-all duration-300 group-hover:fill-cf-white"
                                      d="M8 7.48601V12.514C8 12.8974 8.45737 13.1304 8.80959 12.9217L13.0889 10.4077C13.4148 10.2184 13.4148 9.7816 13.0889 9.58747L8.80959 7.07833C8.45737 6.86964 8 7.1026 8 7.48601Z"
                                      fill="#336CA2"
                                    />
                                  </svg>
                                </span>
                              </button>
                            </>
                          )}
                          {id === 1 && (
                            <img src="/img/nykaa.svg" width={125} alt="nykaa" />
                          )}
                          {id === 2 && (
                            <>
                              <img
                                src="/img/apollo-tyres.png"
                                width={220}
                                alt="apollo tyres"
                              />
                              <a
                                href="/case-study/apollo-tyres-simplify-their-incentive-payouts-with-cashgram-apis-by-cashfree-payments/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-black bg-opacity-25 absolute bottom-0 left-0 right-0 py-3 md:py-4 text-center group">
                                <span className="text-cf-white font-medium text-2sm flex items-center justify-center mx-auto transition-all duration-300 group-hover:text-cf-green">
                                  Read More
                                </span>
                              </a>
                            </>
                          )}
                        </div>
                        <div className="p-4 md:p-[25px]">
                          {usecaseDescription}

                          <div className="absolute bottom-[20px]">
                            <p className="text-tiny md:text-md font-semibold md:font-normal mb-2 md:mb-[15px]">
                              Use case in focus:
                            </p>

                            <span className="text-tiny md:text-2sm bg-cf-light-grey inline-flex items-center px-[15px] py-1.5 rounded font-semibold">
                              <Icon
                                name="tick"
                                width="14px"
                                height="10px"
                                fill="#6933D3"
                                className="mr-3"
                              />
                              {usecaseFeature}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </SliderUseCase>
            </div>
            {/* <div className="center usecase-arrows">
              <button
                type="button"
                onClick={sliderClickHandler}
                data-type="prev"
                className="slider-arrow arrow-left">
                <Icon name="chevron" width="11px" height="7px" />
              </button>
              <button
                type="button"
                onClick={sliderClickHandler}
                data-type="next"
                className="slider-arrow arrow-right">
                <Icon name="chevron" width="11px" height="7px" />
              </button>
            </div> */}
          </div>
        </div>
        <div
          data-spy-title="Pricing"
          data-spy-id="pricing"
          className="py-12 md:py-[96px] bg-cf-light-grey relative">
          <div className="small-container">
            <div className="text-center">
              <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] mx-auto inline-block">
                PRICING
              </p>
              <span className="block font-heading text-lg md:text-xl font-semibold text-cf-hero mb-8 md:mb-12">
                Simple Pricing, No Hidden Charges
              </span>
            </div>
          </div>
          <div className="small-container relative">
            <div className="flex flex-wrap items-start justify-center">
              <div className="max-w-[610px] w-full">
                <div className="bg-cf-white rounded p-4 md:py-5 md:px-[38px]">
                  <span className="inline-block font-body text-tiny md:text-sm font-semibold text-cf-primary uppercase mb-[15px]">
                    Basic
                  </span>
                  <div className="flex-1 flex flex-nowrap md:flex-wrap justify-between md:border-b border-dashed border-cf-stroke md:pb-5 mb-8 md:mb-5">
                    <span className="font-normal text-vtiny leading-4 md:leading-6 md:text-2sm text-cf-hero w-[80px] md:w-[130px]">
                      <span className="font-semibold text-2sm md:text-3md mb-1 md:mb-0">
                        ₹ 2.50
                      </span>
                      <br />
                      <span>Cashgram Charges</span>
                    </span>
                    <span className="font-semibold text-2sm md:text-3md text-cf-hero translate-y-3">
                      +
                    </span>
                    <span className="font-semibold text-2sm text-cf-hero w-[80px] md:w-[135px] md:translate-y-3 md:leading-[30px]">
                      Transfer Charges
                    </span>
                    <span className="font-semibold text-2sm md:text-3md text-cf-hero translate-y-3">
                      &nbsp;
                    </span>
                    <span className="font-semibold text-2sm md:text-3md text-cf-hero w-[80px] md:w-[130px]">
                      &nbsp;
                    </span>
                  </div>
                  <span className="inline-block font-body text-tiny md:text-sm font-semibold text-cf-primary uppercase mb-[15px] pt-5">
                    Premium
                  </span>
                  <div className="flex-1 flex flex-nowrap md:flex-wrap justify-between mb-8 md:mb-6">
                    <span className="font-normal text-vtiny leading-4 md:leading-6 md:text-2sm text-cf-hero w-[80px] md:w-[130px]">
                      <span className="font-semibold text-2sm md:text-3md mb-1 md:mb-0">
                        ₹ 2.00
                      </span>
                      <br />
                      <span>Cashgram Charges</span>
                    </span>
                    <span className="font-semibold text-2sm md:text-3md text-cf-hero translate-y-3">
                      +
                    </span>
                    <span className="font-normal text-vtiny leading-4 md:leading-6 md:text-2sm text-cf-hero w-[80px] md:w-[135px]">
                      <span className="font-semibold text-2sm md:text-3md mb-1 md:mb-0">
                        ₹ 2.5
                      </span>
                      <br />
                      <span>
                        Bank account <br />
                        verification charges
                      </span>
                    </span>
                    <span className="font-semibold text-2sm md:text-3md text-cf-hero translate-y-3">
                      +
                    </span>
                    <span className="font-semibold text-2sm text-cf-hero w-[80px] md:w-[130px] md:translate-y-3 md:leading-[30px]">
                      Transfer Charges
                    </span>
                  </div>
                  <a
                    className="button button-green w-full md:w-auto"
                    href="https://merchant.cashfree.com/merchants/signup?source-action=Cashgram%20Page&action=Sign%20Up&button-id=StartNow_Pricing">
                    Create Account <span className="chevron" />
                  </a>
                </div>
                <div className="cashgram-faq">
                  <FAQ
                    supportLink={false}
                    arrow="arrow"
                    getActive={getAccordionAnimImage}
                    isPayouts
                    data={[
                      {
                        id: 1,
                        q: 'Transfer Charges',
                        a: (
                          <div>
                            <div className="flex-1 flex flex-nowrap md:flex-wrap justify-between">
                              {[
                                {
                                  id1: 0,
                                  item: [
                                    { id2: 0, value: 'IMPS' },
                                    {
                                      id2: 1,
                                      value: '₹ 2.50',
                                    },
                                    {
                                      id2: 2,
                                      value: '₹ 5.00',
                                    },
                                    {
                                      id2: 3,
                                      value: '₹ 10.00',
                                    },
                                  ],
                                },
                                {
                                  id1: 1,
                                  item: [
                                    { id2: 0, value: 'NEFT' },
                                    {
                                      id2: 1,
                                      value: '₹ 1.75',
                                    },
                                    {
                                      id2: 2,
                                      value: '₹ 1.75',
                                    },
                                    {
                                      id2: 3,
                                      value: '₹ 1.75',
                                    },
                                  ],
                                },
                                {
                                  id1: 2,
                                  item: [
                                    { id2: 0, value: 'UPI' },
                                    {
                                      id2: 1,
                                      value: '₹ 3.00',
                                    },
                                    {
                                      id2: 2,
                                      value: '₹ 6.00',
                                    },
                                    {
                                      id2: 3,
                                      value: '₹ 10.00',
                                    },
                                  ],
                                },
                                {
                                  id1: 3,
                                  item: [
                                    { id2: 0, value: '\u00A0' },
                                    {
                                      id2: 1,
                                      value: 'up to ₹1,000',
                                    },
                                    {
                                      id2: 2,
                                      value: '₹1,001 to ₹10,000',
                                    },
                                    {
                                      id2: 3,
                                      value: 'Above ₹10,000',
                                    },
                                  ],
                                },
                              ].map(({ id1, item }) => (
                                <div key={id1}>
                                  {item.map(({ id2, value }) => (
                                    <div
                                      key={id2}
                                      className={`md:mt-4 ${
                                        id2 !== 0 ? 'mt-3' : 'mt-1'
                                      }`}>
                                      <span
                                        className={`font-body font-semibold 
                                        ${
                                          id2 > 0 && id1 !== 3
                                            ? 'text-cf-primary text-tiny md:text-2md'
                                            : ''
                                        } 
                                        ${
                                          id1 === 3
                                            ? 'text-tiny md:text-md'
                                            : ''
                                        }
                                        ${id2 === 0 ? 'text-2sm' : ''}`}>
                                        {value}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                          </div>
                        ),
                      },
                      {
                        id: 2,
                        q: (
                          <span className="flex justify-between">
                            <span>Cards</span>
                            <span className="text-cf-primary">₹ 10.00</span>
                          </span>
                        ),
                        a: (
                          <div className="flex-1 flex flex-nowrap md:flex-wrap">
                            {[
                              {
                                id: 0,
                                img: '/img/icons/mastercard.svg',
                                width: 24,
                                heading: 'Mastercard',
                              },
                              {
                                id: 1,
                                img: '/img/icons/visa.png',
                                width: 30,
                                heading: 'Visa',
                              },
                            ].map(({ id, img, heading, width }) => (
                              <div key={id} className="text-center mr-5">
                                <div className="flex items-center justify-center w-[48px] h-[48px] border border-cf-stroke rounded-full mx-auto mb-2 mt-4">
                                  <img width={width} src={img} alt={heading} />
                                </div>
                                <h5 className="font-body text-tiny md:text-2sm font-normal">
                                  {heading}
                                </h5>
                              </div>
                            ))}
                          </div>
                        ),
                      },
                      {
                        id: 3,
                        q: 'Wallets',
                        a: (
                          <div className="flex-1 flex flex-nowrap md:flex-wrap">
                            {[
                              {
                                id: 0,
                                img: '/img/icons/amazon-black.png',
                                width: 48,
                                heading: 'Amazon Pay',
                                value: (
                                  <span className="font-semibold text-2sm md:text-2md text-cf-primary">
                                    1.00%
                                  </span>
                                ),
                              },
                              {
                                id: 1,
                                img: '/img/icons/paytm.svg',
                                width: 32,
                                heading: 'Paytm',
                                value: (
                                  <span className="font-medium text-tiny">
                                    As applicable on your Paytm business account
                                  </span>
                                ),
                              },
                            ].map(({ id, img, heading, width, value }) => (
                              <div
                                key={id}
                                className={`mr-5 md:text-left ${
                                  id
                                    ? ''
                                    : 'border-r border-dashed border-cf-stroke pr-5 md:pr-8 mr-5 md:mr-8'
                                }`}>
                                <div className="mt-4 text-left h-[40px] md:h-auto flex items-center">
                                  {value}
                                </div>
                                <div className="text-center inline-block">
                                  <div className="text-center inline-flex items-center justify-center w-[48px] h-[48px] border border-cf-stroke rounded-full mx-auto mb-2 mt-4">
                                    <img
                                      width={width}
                                      src={img}
                                      alt={heading}
                                    />
                                  </div>
                                  <h5 className="font-body text-tiny md:text-2sm font-normal w-max">
                                    {heading}
                                  </h5>
                                </div>
                              </div>
                            ))}
                          </div>
                        ),
                      },
                    ]}
                    style={{
                      border: 'none',
                      margin: '0',
                      padding: '0',
                    }}
                  />
                </div>
              </div>
              <div className="pricing-cards dark payouts-price-card xl:!max-w-[400px] !max-w-[610px] !min-h-[460px] mt-8 xl:mt-0 !ml-0 xl:!ml-5">
                <div className="card-content">
                  <small className="font-semibold text-sm text-cf-cold-purple opacity-80">
                    ENTERPRISES
                  </small>
                  <p className="lead bold">
                    Custom pricing designed for enterprises
                  </p>
                  <ul>
                    {[
                      {
                        id: 0,
                        item: 'Early access to new features',
                      },
                      {
                        id: 1,
                        item: 'Dedicated account manager',
                      },
                      {
                        id: 2,
                        item: 'Discounted pricing',
                      },
                      {
                        id: 3,
                        item:
                          'Support over WhatsApp in addition to other channels',
                      },
                    ].map(({ id, item }) => (
                      <li key={id}>
                        <span className="flex items-center justify-center mt-0.5">
                          <Icon
                            name="tick"
                            width="9.2px"
                            height="7px"
                            fill="#fff"
                          />
                        </span>
                        <span className="!text-cf-white max-w-[300px]">
                          {item}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <p className="card-get text-cf-cold-purple mb-5">
                    Get in touch with our sales team to explore the right
                    product(s) for your payment needs and get custom pricing.
                  </p>
                </div>
                <a
                  href="https://www.cashfree.com/contact-sales?source-action=Cashgram%20Page&action=Contact%20Sales&button-id=ContactSales_Pricing"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button button-outline no-shadow mx-6 mb-6">
                  Contact Sales
                  <span className="chevron" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Scrollspy>

      <div className="container">
        <FAQ
          open="0"
          data={[
            {
              id: 0,
              q: 'What is a Cashgram?',
              a: (
                <p>
                  Cashgram is an instant payout link used by businesses to send
                  money to their customers, vendors, or partners when only their
                  email address or phone number is known.
                </p>
              ),
            },
            {
              id: 1,
              q: 'Is Cashgram different from Payout links?',
              a: (
                <p>
                  Cashgram is a payout link with many additional features. You
                  can customize your Cashgram links with your company logo and
                  color to reflect your brand. You can also set up a link expiry
                  time and verify the beneficiary&apos;s name before sending out
                  the money.
                </p>
              ),
            },
            {
              id: 2,
              q: 'What are the advantages of using a Payout link?',
              a: (
                <p>
                  Payout links make sending out money simple and easy. It takes
                  away all the operational overhead associated with bank
                  transfers or payouts. You can simply create a payout link with
                  a few clicks. We will notify the user and they can redeem the
                  money any time at their convenience.
                </p>
              ),
            },
            {
              id: 3,
              q: 'How can I start using Cashgram?',
              a: (
                <p>
                  To start using Cashgram you will need to sign-up on Cashfree.
                  If you already have a Payouts account click on the
                  &quot;Cashgram&quot; tab in the Payouts dashboard or use our
                  API.
                </p>
              ),
            },
            {
              id: 4,
              q: 'Can I create multiple Payout links at a time?',
              a: (
                <p>
                  Yes, with Cashgram&apos;s batch upload feature, you can create
                  and send bulk instant payout links to a large number of
                  beneficiaries. All you need to do is click on &apos;download
                  sample file’, add all the required details in the sample
                  downloaded, save the file, and click on upload file.
                </p>
              ),
            },
            {
              id: 5,
              q: 'Can the customer view and redeem a Payout link at any time?',
              a: (
                <p>
                  Yes, the recipient will be able to view their Cashgram Payout
                  link at any time. Redemption, however, can only be done within
                  the expiry time set by the merchant while sending the link.
                </p>
              ),
            },
            {
              id: 6,
              q: 'Can customers also redeem money to their UPI account?',
              a: (
                <p>
                  If you have UPI enabled in your Payout account your customers
                  will be able to redeem a Cashgram to their UPI account.
                </p>
              ),
            },
            {
              id: 7,
              q: 'How much does sending a Cashgram link cost?',
              a: (
                <p>
                  Please get in touch with our sales team to get a custom
                  pricing as per your needs.
                </p>
              ),
            },
            {
              id: 8,
              q: 'How secure is Cashgram?',
              a: (
                <p>
                  Cashgram is a secure way to transfer money to your customers.
                  Once you create a Cashgram link for a beneficiary only that
                  beneficiary with the provided phone number/email address can
                  use that link to redeem the amount. Also, it is active only
                  for a limited period of time, and as a merchant you can
                  control the expiry time of each link generated. Additionally,
                  Merchants can also enable ‘Beneficiary name verification’
                  feature to prevent incorrect transfers and ensure the money
                  goes to the intended beneficiary account only.
                </p>
              ),
            },
            {
              id: 9,
              q:
                'How will you verify the identity of the user for whom the Payout link is generated?',
              a: (
                <p>
                  We authenticate users using OTP so that only authorized users
                  can view/redeem a Cashgram link. Without a valid OTP nobody
                  can view/redeem that particular link.
                </p>
              ),
            },
            {
              id: 10,
              q: 'Will I be notified if the Cashgram link I generated is used?',
              a: (
                <p>
                  Once the customer redeems a Cashgram link its status changes
                  from ACTIVE to REDEEMED instantly. You will be able to view
                  this on the dashboard. You will also be notified over email
                  once a Cashgram has been successfully redeemed.
                </p>
              ),
            },
          ]}
          customerData={[
            {
              id: 0,
              q: 'What is a Cashgram?',
              a: (
                <p>
                  Cashgram is a way to instantly send out money. If you have
                  received a Cashgram Payout link from a merchant, you can
                  redeem this by visiting the link and submitting your recipient
                  details. You should then receive the money instantly.
                </p>
              ),
            },
            {
              id: 1,
              q:
                'I received a Cashgram link on email but the mobile number mentioned on it is incorrect?',
              a: (
                <p>
                  It seems that the merchant entered a different mobile number
                  while generating the link. Please reach out to the merchant to
                  get this changed.
                </p>
              ),
            },
            {
              id: 2,
              q:
                'I did not receive the money from my Payout link, what should i do?',
              a: (
                <p>
                  A Cashgram transfer could fail because of network or banking
                  infrastructure issues. Please reach out to the merchant so
                  that they can generate a new link for you.
                </p>
              ),
            },
            {
              id: 3,
              q:
                'My Payout link status is showing success but I have not received the money in my account. What should I do?',
              a: (
                <p>
                  The money should be transferred into your account in some
                  time. If you do not receive the amount within one hour please
                  mail us at support@cashfree.com. Do mention your email id,
                  phone number, Cashgram Id and merchant name in your email.
                </p>
              ),
            },
          ]}
        />
      </div>

      <OtherProducts
        data={[
          {
            id: 0,
            title: 'Subscriptions',
            body:
              'Accept recurring payments by auto-debiting customers’ accounts via standing instructions on card, e-mandate via net banking option or UPI\xa0AutoPay.',
            url: 'https://www.cashfree.com/recurring-payment',
            iconname: '/img/icons/subscriptions.svg',
          },
          {
            id: 1,
            title: 'Payment Links',
            body:
              'No-code payment links to collect payments over WhatsApp, SMS, Facebook, Twitter and other\xa0channels.',
            url: 'https://www.cashfree.com/payment-links',
            iconname: '/img/icons/payment-links.svg',
          },
          {
            id: 2,
            title: 'Easy Split',
            body:
              'Split vendor commissions and manage marketplace\xa0settlements.',
            url: 'https://www.cashfree.com/easy-split/split-payment-gateway',
            iconname: '/img/icons/easy-split-2.svg',
          },
          {
            id: 3,
            title: 'Auto Collect',
            body:
              'Collect and reconcile IMPS/NEFT/UPI payments using unique virtual accounts and UPI\xa0IDs.',
            url: 'https://www.cashfree.com/auto-e-collect',
            iconname: '/img/icons/auto-collect-3.svg',
          },
        ]}
      />
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=Cashgram%20Page&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=Cashgram%20Page&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
      <ModalVideo
        OpenVideoModal={OpenVideoModal}
        OpenVideoModalToggle={OpenVideoModalToggle}
        VideoSrc="https://www.youtube.com/embed/yRGiEXzEDok"
      />
    </Layout>
  );
};

export default Cashgram;
