import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import './SliderUseCase.scss';

function SliderUseCase({ children, settings }) {
  return <Slider {...settings}>{children}</Slider>;
}

SliderUseCase.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.shape({}),
};

SliderUseCase.defaultProps = {
  settings: {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  },
};

export default SliderUseCase;
